.auto-resizable-iframe {
    max-width: 620px;
    margin: 0px auto;
  }
  
  .auto-resizable-iframe > div {
    position: relative;
    padding-bottom: 75%;
    height: 600px;
  }
  
  .auto-resizable-iframe iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .mainSpace > div.ant-space-item:nth-child(3) {
    width: 100%;
  }